<template>
  <v-sheet class="pa-2">
    <v-row>
      <span v-if="!chartData">No Data available</span>
      <v-col cols="12" v-else ref="chart" style="height: 400px"></v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import Papa from "papaparse";
import Dygraph from "dygraphs";

export default {
  name: "Dygraph",
  props: {
    data: {
      type: [Object, Array],
      required: false,
      default: () => null,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {
      const { data } = this;
      if (data != null) {
        return Papa.unparse(data);
      }
      return null;
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    data(to, from) {
      if (from == null) {
        this.createChart();
      } else {
        this.updateChart();
      }
    },
  },
  mounted() {
    if (this.chartData) {
      this.createChart();
    }
  },
  beforeDestroy() {
    this.destroyChart();
  },
  methods: {
    createChart() {
      console.debug("Creating dygraph");
      this.chart = new Dygraph(this.$refs.chart, this.chartData, this.options);
    },
    updateChart() {
      console.debug("Updating Dygraph");
      const options = { file: this.chartData, ...this.options };
      this.chart.updateOptions(options);
    },
    destroyChart() {
      console.debug("Destroying dygraph");
      this.chart.destroy();
      this.chart = null;
    },
  },
};
</script>

<style></style>
